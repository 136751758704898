@media screen and (min-width:1000px) {
    .pw-2 {
        padding: 0 4rem;
    }
}

@media print {

    .footer {
        position: absolute;
        bottom: 0px;
        left:60px;
        width: 85%;
    }
    
}